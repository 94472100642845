<template>
  <s-drawer
    :visible="show"
    max-size="89%"
    :show-close="true"
    :append-to-body="true"
    class="custom-detail-dialog"
    @close-from-icon="closeByIcon"
    @close-from-mask="closeByMask"
  >
    <template #title>
      <div class="header-title">
        {{ customizationContentTitle }}
      </div>
    </template>
    <div class="content">
      <!-- 履约时效说明 -->
      <div
        v-if="stockingTimeTip"
        class="stocking-time"
      >
        <img 
          class="stocking-time__icon"
          :src="stockingTimeIcon"
        />
        <span class="stocking-time__tip">{{ stockingTimeTip }}</span>
      </div>
      <template v-if="resizeEffects.length > 0">
        <div class="block-title">
          {{ effectsTip }}:
        </div>
        <div class="block-content">
          <div
            v-for="(item, index) in resizeEffects"
            :key="item+index"
            class="preview-img"
            @click="viewBigPicture('effects', index)"
          >
            <img
              class="lazyload img-content"
              :src="LAZY_IMG"
              :data-src="item"
              alt="shein picture"
            />
          </div>
        </div>
      </template>

      <template v-if="texts.length > 0">
        <div class="block-title">
          {{ textsTip }}:
        </div>
        <div class="block-content">
          <div
            v-for="(text, index) in texts"
            :key="text+index"
            class="text-content"
          >
            {{ text }}
          </div>
        </div>
      </template>

      <template v-if="resizeImages.length > 0">
        <div class="block-title">
          {{ imagesTip }}:
        </div>
        <div class="block-content">
          <div
            v-for="(image, index) in resizeImages"
            :key="image+index"
            class="custom-img"
            @click="viewBigPicture('images', index)"
          >
            <img
              class="lazyload img-content"
              :src="LAZY_IMG"
              :data-src="image"
              alt="shein picture"
            />
          </div>
        </div>
      </template>
    </div>
    <div class="foot">
      <s-button 
        :type="['primary', 'H40PX']"
        :width="'100%'"
        @click="close"
      >
        {{ okTip }}
      </s-button>
    </div>
  </s-drawer>
</template>

<script>
import mitt from 'mitt'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { defineComponent } from 'vue'
import CustomDaEvent from './utils/analysis.js'

const eventBus = mitt()

export default defineComponent({
  name: 'CustomDetail',
  components: {
    SButton,
    SDrawer,
  },
  props: {
    previewInstance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showDetailDialog: false,
      customizationContentTitle: '',
      okTip: '',
      effectsTip: '',
      resizeEffects: [],
      originEffects: [],
      textsTip: '',
      texts: [],
      imagesTip: '',
      resizeImages: [],
      originImages: [],
      previewStatus: {
        show: false
      },
      analysisData: {},
      LAZY_IMG: LAZY_IMG,
      onOpen: null,
      onClose: null,
      stockingTimeIcon: '',
      stockingTimeTip: '',
    }
  },
  computed: {
    show: {
      get() {
        return this.showDetailDialog
      },
      set(val){
        if(!val) this.$emit('close')
        this.showDetailDialog = val
        if(!this.showDetailDialog) {
          eventBus.off()
          CustomDaEvent.click_custom_content_close()
          this.texts = []
          this.resizeEffects = []
          this.resizeImages = []
        }
      }
    },
  },
  watch: {
    show(n) {
      if (n) {
        this?.onOpen?.()
      } else {
        this?.onClose?.()
      }
    }
  },
  methods: {
    open(ctx, onOpen, onClose) {
      this.initData(ctx)
      this.show = true
      this.onClose = onClose
      this.onOpen = onOpen
      // 曝光埋点
      CustomDaEvent.expose_custom_content({
        images: this.resizeImages?.length || 0,
        preview: this.resizeEffects?.length || 0,
        texts: this.texts?.length || 0,
      }, this.analysisData)
    },
    close() { // 外部触发关闭
      this.show = false
    },
    closeByIcon() { // icon触发关闭
      this.show = false
      this.$emit('closeByIcon')
    },
    closeByMask() { // mask触发关闭
      this.show = false
      this.$emit('closeByMask')
    },
    initData(ctx) {
      const { customizationContentTitle, okTip, effectsTip, resizeEffects, originEffects, textsTip, texts, imagesTip, resizeImages, originImages, onEvent, analysisData = {}, stockingTimeIcon = '', stockingTimeTip = '' } = ctx || {}

      this.customizationContentTitle = customizationContentTitle
      this.okTip = okTip

      this.stockingTimeIcon = stockingTimeIcon
      this.stockingTimeTip = stockingTimeTip
      this.effectsTip = effectsTip
      this.resizeEffects = resizeEffects || []
      this.originEffects = originEffects || []

      this.textsTip = textsTip
      this.texts = texts || []

      this.imagesTip = imagesTip
      this.resizeImages = resizeImages || []
      this.originImages = originImages || []

      this.analysisData = analysisData
      if(onEvent) {
        Object.keys(onEvent).forEach(key => {
          eventBus.on(key, onEvent[key])
        })
      }
    },
    viewBigPicture(type, index) {
      this.previewInstance.open({
        previewStatus: {
          type,
          index,
        },
        effectsTip: this.effectsTip,
        resizeEffects: this.resizeEffects,
        originEffects: this.originEffects,
        imagesTip: this.imagesTip,
        resizeImages: this.resizeImages,
        originImages: this.originImages,
      })
      CustomDaEvent.click_custom_content_imagedetail()
    },
    closePreviewPicture(val){
      this.previewStatus.show = val
    },
  },
})
</script>

<style scoped lang="less">
.custom-detail-dialog {
  :deep(.sui-drawer__container) {
    border-radius: 12px 12px 0 0;
  }
  :deep(.sui-drawer__header) {
    border-bottom: none;
    text-align: center;
  }
  :deep(.header-title) {
    text-align: center;
  }
  :deep(.sui-drawer__close-btn) {
    font-size: 20px!important;
    right: 24/75rem;
    top: 20/75rem;
  }
}
.header-title {
  height: 80/75rem;
  padding: 20/75rem 0;
  font-size: 16px;
  font-weight: 590;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  padding: 14/75rem 24/75rem 16/75rem;
  overflow-y: auto;
  .stocking-time {
    color: #959595;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-bottom: 32/75rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    &__icon {
      width: 12px;
      height: 12px;
      vertical-align: -2px;
    }
    &__tip {
      margin-left: 8/75rem;
    }
  }
}
.foot {
  padding: 16/75rem 24/75rem 16/75rem;
}
.block-title {
  font-size: 12px;
  font-weight: 700;
  color: #767676;
  margin-bottom: 16/75rem;
}
.block-content {
  display: flex;
  flex-wrap: wrap;
  gap: 8/75rem;
  margin-bottom: 48/75rem;
  .img-content {
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
  }
  .preview-img {
    width: 122/75rem;
    height: 122/75rem;
    position: relative;
    overflow: hidden;
  }
  .text-content {
    background: #F6F6F6;
    padding: 12/75rem 24/75rem;
    color: #666;
  }
  .custom-img {
    width: 134/75rem;
    height: 134/75rem;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
}
</style>
