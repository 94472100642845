import { createSSRApp } from 'vue'
import { useVueErrorHandler } from 'public/src/pages/common/monitor/index.js'
import App from './App.vue'
import { createStore } from './store'

export function createApp(context) {
  const store = createStore(context)

  store.commit('checkout/asyncDataMutations', context)
  
  if(typeof window !== 'undefined' && gbCommonInfo.NODE_SERVER_ENV !== 'production') {
    window.__g_store = store
  }
  
  const app = createSSRApp(App)
  app.use(store)
  useVueErrorHandler(app)

  return { app }
}
