import { createApp } from './app'
import { businessLibInitializeForCSR } from 'public/src/pages/product_app/business-lib-initialize/csr.js'
import { triggerAbtRouter } from '@shein-aidc/basis-abt-router'
import { useSaDirective } from '@shein-aidc/basis-sa-event-center'

triggerAbtRouter({ routeName: 'checkout' })

const { app } = createApp(window.gbCheckoutSsrData)
businessLibInitializeForCSR({ router: null, app })
useSaDirective(app)

app.mount('#checkout-app')
useSaDirective(app)

export { app }
