import { createApp } from 'vue'
import container from './container.vue'
import ImagePreviewDialog from './components/ImagePreviewDialog.vue'

class CustomDetailDialog {
  constructor(el = null) {
    if(!CustomDetailDialog.instance) {
      this.initialized = false
      this.instance = null
      this.previewInstance = null
      if(typeof window != 'undefined' ) this.init(el) // 非ssr环境下初始化, 否则由用户初始化
      CustomDetailDialog.instance = this
    }
    return CustomDetailDialog.instance // 单例模式,避免弹窗重复创建和销毁
  }
  async init(el = null) {
    if(this.initialized) return
    this.initialized = true

    const appDom1 = document.createElement('div')
    this.previewInstance = createApp(ImagePreviewDialog).mount(appDom1)
    document.body.appendChild(this.previewInstance.$el)

    const appDom2 = document.createElement('div')
    this.instance = createApp(container, {
      previewInstance: this.previewInstance
    }).mount(appDom2)
    if(el) el.appendChild(this.instance.$el)
    else document.body.appendChild(this.instance.$el)
  }
  open(props, onOpen, onClose) {
    if(!this.instance) {
      console.error('CustomDetailDialog instance is not exist')
      return
    }
    this.instance.open(props, onOpen, onClose)
  }
  close() {
    this.instance.close()
  }
  // TODO: 待删除，以下监听方式vue3暂不支持，也没地方调用
  // addListener(options){
  //   Object.keys(options).forEach(name => {
  //     if(typeof options[name] == 'function') this.instance.$on(name, options[name])
  //   })
  // }
  // removeListener(options){
  //   Object.keys(options).forEach(name => {
  //     if(typeof options[name] == 'function') this.instance.$off(name, options[name])
  //   })
  // }
}

export default new CustomDetailDialog()
